jQuery(document).ready( function() {

    var urlParams = new URLSearchParams(window.location.search);

    // Convert the URLSearchParams object to a query string
    var queryString = urlParams.toString();

    jQuery('.fixed-tariff .quote-cta-tc img').click(function() {
        jQuery('#tariff-details-panel').toggle();
    }, function() {
        jQuery('#tariff-details-panel').toggle();
    });

    jQuery('.variable-tariff .quote-cta-tc img').click(function() {
        jQuery('.variable-panel').toggle();
    }, function() {
        jQuery('.variable-panel').toggle();
    });

    jQuery('#info-img').hover(function() {
        jQuery('#info-provider-panel').show();
    }, function() {
        jQuery('#info-provider-panel').hide();
    });

    jQuery('.con-enterknown').on('click', function() {
        jQuery('#low-panel').hide();
        jQuery('#medium-panel').hide();
        jQuery('#high-panel').hide();
        jQuery('.rebel-panel-wrap').hide();
        jQuery('#enter-own-consump').show();
    });

    // on hover of the info icon, show the info panel
    jQuery('.info-icon').hover(function() {
        jQuery(this).parent().find('#info-provider-panel').show();
    }, function() {
        jQuery(this).parent().find('#info-provider-panel').hide();
    });
    

    jQuery('#postcode-button').on('click', function() {
        let postcode = jQuery('.rebel-postcode').val();
        let button = jQuery('#postcode-button');
                button.find("p").hide(); // Hide the <p> element inside the button
                button.find("img").show()
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "rebel_get_addresses", postcode: postcode},
            success: function(data) {
                data = data.toString();
                data = data.replace(/0+$/, '');
                var dataArray = JSON.parse(data);
   
                var select = jQuery('#rebel-addresses-select');
                select.css('display', 'block');
                jQuery('.rebel-postcode').css('width', '100%');
                jQuery(select).css('width', '100%');
                dataArray.forEach(function(address) {
                    var option = jQuery('<option></option>');
                option.text(address.fullAddress);
    
                option.attr('data-street', address.street);
                option.attr('data-posttown', address.posttown);
                option.attr('data-postcode', address.postcode);
                option.attr('data-number', address.number);
                option.attr('data-uprn', address.uprn);
                option.attr('data-mpan', address.mpan);
                option.attr('data-flat', address.flat);
                option.attr('data-buildingname', address.buildingname);
                option.attr('data-fullAddress', address.fullAddress);

                select.append(option); // Use jQuery's append method
                });
                button.find("p").show(); // Hide the <p> element inside the button
                button.find("img").hide()
                button.hide();
                jQuery('.rebel-postcode').css('max-width', '100%');
                jQuery('.rebel-postcode').css('width', '100%');
            },
        });
    });

    jQuery('.faq-question h4').click(function() {
        jQuery(this).parent().find('.faq-answer').slideToggle();
        // if .minus-img is hidden, show it else hide it and show .plus-img
        jQuery(this).parent().find('.minus-img').toggle();
        jQuery(this).parent().find('.plus-img').toggle();
      });

    jQuery('#rebel-addresses-select').on('change', function() {
        jQuery('#rebel-compare-now').css('display', 'flex');
        var selectedOption = jQuery('#rebel-addresses-select option:selected');
        var street = selectedOption.data('street'); // Retrieve data attribute values
        var posttown = selectedOption.data('posttown');
        var postcode = selectedOption.data('postcode');
        var number = selectedOption.data('number');
        var uprn = selectedOption.data('uprn');
        var mpan = selectedOption.data('mpan');
        var flat = selectedOption.data('flat');
        var buildingname = selectedOption.data('buildingname');
        var fullAddress = selectedOption.data('fulladdress');

        var queryString = window.location.search.substring(1);

        // Split the query string into an array of key-value pairs
        var queryParams = queryString.split("&");

        // Create an object to store the parameter-value pairs
        var params = {};
        queryParams.forEach(function(param) {
        var pair = param.split("=");
        if (pair.length === 2) {
            var key = decodeURIComponent(pair[0]);
            var value = decodeURIComponent(pair[1]);
            params[key] = value;
        }
        });

        var source = params["source"];
        var sid = params["sid"];
        var ssid = params["ssid"];
        var gclid = params["glid"];
        var msclkid = params["msclkid"];
        var affiliateID = params['affiliateID'];
        var transactionID = params['transactionID'];
        var clickref = params['click_ref'];
        
        // Build the query string with data attributes
        var queryString = 'fullAddress=' + encodeURIComponent(fullAddress) +
                        '&street=' + encodeURIComponent(street) +
                        '&posttown=' + encodeURIComponent(posttown) +
                        '&postcode=' + encodeURIComponent(postcode) +
                        '&number=' + encodeURIComponent(number) +
                        '&uprn=' + encodeURIComponent(uprn) +
                        '&mpan=' + encodeURIComponent(mpan) +
                        '&flat=' + encodeURIComponent(flat) +
                        '&buildingname=' + encodeURIComponent(buildingname) +
                        '&source=' + source +
                        '&sid=' + sid +
                        '&ssid=' + ssid +
                        '&gclid=' + gclid +
                        '&msclkid=' + msclkid +
                        '&affiliateID=' + affiliateID +
                        '&transactionID=' + transactionID +
                        '&click_ref=' + clickref;

        var newHref = '/energy/your-online-energy-quote/?' + queryString;
        jQuery('#rebel-compare-now a').attr('href', newHref);
    });

    jQuery('.rebel-gen-quote-pre').on('click', function() {
        jQuery('.rebel-energy-options').hide();
        jQuery('.rebel-housesize').hide();
        //jQuery('.rebel-energy-consump').show();
    });

    // when .rebel-compare-loader is visible one by one display the li's of the ul
    jQuery('.rebel-compare-loader').each(function() {
        var $li = jQuery(this).find('li');
        var delay = 1000; // milliseconds
        $li.each(function(i) {
            jQuery(this).delay(delay * i).fadeIn(500);
        });
    });

    var imageState = "plus";
    jQuery('.mobile-quote-consumption').on('click', function() {
        jQuery('.quote-consumption').toggle();
        var imgElement = jQuery(this).find(".toggle-img");

        // Toggle the image source based on the current state
        if (imageState === "plus") {
          imgElement.attr("src", "https://switcheroo.co.uk/wp-content/uploads/2023/08/minus.png");
          imageState = "minus"; // Update the state
        } else {
          imgElement.attr("src", "https://switcheroo.co.uk/wp-content/uploads/2023/08/plus.png");
          imageState = "plus"; // Update the state
        }
    });

    function generateUniqueKey() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let key = '';
        
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            key += characters.charAt(randomIndex);
        }
        
        return key;
    }

    jQuery('.details-btn').on('click', function() {
        var panel = jQuery(this).next('.tariff-details-panel');
        var plusimg = jQuery(this).find('.plus-img');
        var minusimg = jQuery(this).find('.minus-img');
        if (jQuery(panel).is(':hidden')) {
            panel.css('display', 'flex');
            plusimg.hide();
            minusimg.show();
        } else {
            panel.css('display', 'none');
            plusimg.show();
            minusimg.hide();
        }
    });

    // function getDaySuffix(day) {
    //     if (day >= 11 && day <= 13) {
    //         return 'th';
    //     }
    //     switch (day % 10) {
    //         case 1:
    //             return 'st';
    //         case 2:
    //             return 'nd';
    //         case 3:
    //             return 'rd';
    //         default:
    //             return 'th';
    //     }
    // }

    // function formatDate(dateString) {
    //     var date = new Date(dateString);
    
    //     var daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    //     var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    //     var dayOfWeek = daysOfWeek[date.getUTCDay()];
    //     var day = date.getUTCDate();
    //     var month = months[date.getUTCMonth()];
    //     var year = date.getUTCFullYear();
    
    //     var suffix = getDaySuffix(day); // Make sure to define the getDaySuffix function
    
    //     var formattedDate = dayOfWeek + ' ' + day + suffix + ' ' + month + ' ' + year;
        
    //     return formattedDate;
    // }
    
    // Check if the 'redirectJB' parameter exists and has a value of 'yes'
    if (urlParams.has('redirectJB') && urlParams.get('redirectJB') === 'yes') {
        jQuery('.rebel-personal-details h1').text('Please confirm your details');
        jQuery('.rebel-energy-options').hide();
        jQuery('.rebel-compare-loader > .wrapper > .rebel-loader-box > p').text('Comparing energy tariffs...');
        jQuery('.rebel-gen-quote').click();
    }
    
});

// Rebel Price Scroll Down
jQuery(document).on("click", ".rebel-price#scrollDown", function(){
    var jQueryelement = jQuery('.site-main');
    jQuery('html, body').animate({
        scrollTop: jQueryelement.offset().top + jQueryelement.outerHeight() - jQuery(window).height()
    }, 1000);
});

//////////// New Rebel Journey ////////////
jQuery(window).on("load", function(){
    if(jQuery("body").hasClass("rebel-journey")){
        // AJAX
        function selectTarrif(){
            jQuery(".content-part:not(.selected-tariff).active").removeClass("active");
            jQuery(".content-part.selected-tariff").removeClass("active");
            jQuery(".content-part.tariff").addClass("active");
            // Hero Content
            jQuery(".hero-inner-content h1").html("Great news, you can save today!");
            jQuery(".hero-inner-content p").html("Our search is complete, and we are pleased to present you with the best online offer available. Enjoy the benefits of your smart choice.");
        }
        function selectConsumption(){
            jQuery(".content-part.selected-tariff").addClass("active");
            jQuery(".content-part:not(.selected-tariff).active").removeClass("active");
            jQuery(".content-part.consumption").addClass("active");
            // Hero Content
            jQuery(".hero-inner-content h1").html("Tell us more about your Consumption");
            jQuery(".hero-inner-content p").html("Sharing a bit more about your current energy usage allows us to pinpoint exactly where and how you can save.");
        }
        function showForm(){
            jQuery(".content-part.selected-tariff").addClass("active");
            jQuery(".content-part:not(.selected-tariff).active").removeClass("active");
            jQuery(".content-part.form").addClass("active");
            // Hero Content
            jQuery(".hero-inner-content h1").html("Complete your switch!");
            jQuery(".hero-inner-content p").html("Complete your switch by providing your details, so we can finalise the process and ensure a smooth transition to your new plan. ");
        }
        function generateRebelQuote(houseType, numberBedrooms, houseChange = null){ 
            var houseType = houseType,
                numberBedrooms = numberBedrooms;
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: {action: "generate_rebel_quote", fullAddress: fullAddress, street: street, posttown: posttown, postcode: postcode, number: number, uprn: uprn, mpan: mpan, flat: flat, buildingname: buildingname, supplyType: supplyType, houseType: houseType, numberBedrooms: numberBedrooms, useHouseConsumption: useHouseConsumption},
                success: function(data) {
                    let params = {
                        source: jQuery('#source').val(),
                        ssid: jQuery('#ssid').val(),
                        gclid: jQuery('#gclid').val(),
                        msclkid: jQuery('#msclkid').val(),
                        campid: jQuery('#campid').val(),
                        industry: jQuery('#industry').val(),
                        sid: jQuery('#sid').val(),
                        c1: jQuery('#c1').val(),
                        c2: jQuery('#c2').val(),
                        c3: jQuery('#c3').val(),
                        c4: jQuery('#c4').val(),
                        c5: jQuery('#c5').val(),
                        c6: jQuery('#c6').val(),
                        fullAddress: jQuery('#fullAddressreg').val(),
                        number: jQuery('#numberreg').val(),
                        flat: jQuery('#flatreg').val(),
                        street: jQuery('#streetreg').val(),
                        posttown: jQuery('#posttownreg').val(),
                        postcode: jQuery('#postcodereg').val(),
                        firstname: jQuery('#firstnamereg').val(),
                        phone1: jQuery('#phonereg').val(),
                        email: jQuery('#emailreg').val(),
                        profileClass: jQuery('#profileClassreg').val(),
                        fueltype: jQuery('#c3').val().toLowerCase().includes('both') ? "dualFuel" : "electricityOnly",
                        mprn: jQuery('#mprn').val(),
                        uprn: jQuery('#uprn').val(),
                        mpan: jQuery('#mpan').val(),
                        elecUsage: jQuery('#elecUsagereg').val(),
                        gasUsage: jQuery('#gasUsagereg').val(),
                        fixedpaymentAmountElectricity: jQuery('#fixedamountElec').val(),
                        fixedpaymentAmountGas: jQuery('#fixedamountGas').val(),
                        productCode: jQuery('#productCode-reg').val(),
                        fixedTariffID: jQuery('#fixedTariffID-reg').val(),
                        click_ref: jQuery('#click_ref').val(),
                        affiliateID: jQuery('#rebel-affiliateID').val() || (jQuery('#awc').val() ? 'AWIN' : 'Switcheroo'),
                        awc: jQuery('#awc').val(),
                        awcr: jQuery('#awcr').val(),
                        awpid: jQuery('#awpid').val(),
                        awaid: jQuery('#awaid').val(),
                        awgid: jQuery('#awgid').val(),
                        awbid: jQuery('#awbid').val(),
                        redirectJB: "yes" 
                    };
                    
                    // Error Catching
                    if (data.includes("Internal server error")) {
                        jQuery(".content-part.active").removeClass("active");
                        jQuery(".content-part.house-size").addClass("active");
                        jQuery(".rebel-journey .page-loader-wrapper").hide();
                        jQuery(".rebel-journey .page-content-wrapper").fadeIn(250);
                        return;
                    }
                    if (data.includes("Non domestic customer")) {
                        jQuery(".content-part.active").removeClass("active");
                        jQuery(".content-part.non-domestic").addClass("active");
                        jQuery(".rebel-journey .page-loader-wrapper").hide();
                        jQuery(".rebel-journey .page-content-wrapper").fadeIn(250);
                        return;
                    }
                    if (data.includes("undefined cannot be resolved")) {
                        // S1
                        var redirectUrl = "/compare/consumer-energy-style-1-offers";
                        redirectUrl += '?' + Object.entries(params)
                            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                            .join('&');
                        // Perform the redirection
                        window.location.href = redirectUrl;
                    }
                    if (data.includes("Provide household size")) {
                        jQuery(".content-part.active").removeClass("active");
                        jQuery(".content-part.house-size").addClass("active");
                        jQuery(".rebel-journey .page-loader-wrapper").hide();
                        jQuery(".rebel-journey .page-content-wrapper").fadeIn(250);
                        return;
                    }
                    data = data.toString().replace(/0+$/, '');
                    var jsonData = JSON.parse(data),
                        tariffs = jsonData.tariffs;
                    // Error generating quote
                    if (!tariffs) { 
                        if(journeyStyle == "s2"){
                            var redirectUrl = "/comparison-complete/consumer-energy-s2-side";
                            redirectUrl += '?' + Object.entries(params)
                                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                                .join('&');
                            // Perform the redirection
                            window.location.href = redirectUrl;
                        } else {
                            var redirectUrl = "/compare/consumer-energy-style-1-offers";
                            redirectUrl += '?' + Object.entries(params)
                                .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                                .join('&');
                            // Perform the redirection
                            window.location.href = redirectUrl;
                        }
                        return;
                    }
                    // Loop tariffs and set fixed to one with valid tariffID
                    for (var i = 0; i < tariffs.length; i++) {
                        if (tariffs[i].tariffId == 207) {
                            var fixedTariff = tariffs[i];
                        }
                        if (tariffs[i].tariffId == 302) {
                            var variableTariff = tariffs[i];
                        }
                    }
                    // DEV
                    // var fixedTariff = jsonData.tariffs[0],
                    //    variableTariff = jsonData.tariffs[0];  
                    // END DEV
                    var quoteBasis = jsonData.quoteBasis,  
                        supplyType = quoteBasis.supplyType === 'both' ? "Gas and electric" : quoteBasis.supplyType === 'electric' ? "Electric" : "Gas",
                        dualFuel = "dualFuel",
                        elecOnly = "electricityOnly";
                    if (supplyType === "Gas and electric") { 
                        jQuery('#mprnreg').val(quoteBasis.gas.mprn);
                    }
                    jQuery('#fuelTypereg').val(supplyType === "Gas and electric" ? dualFuel : elecOnly);
                    jQuery('#fuelTypeRev').val(supplyType === "Gas and electric" ? '120' : '60');
                    jQuery('#summary-supplytype').text(supplyType);
                    if (supplyType === "Gas and electric") {
                        var gasUsage = quoteBasis.gas.calculationData.consumption;
                        jQuery('#gasUsagereg, #gas-consumption-value').val(gasUsage);
                        jQuery('#gasUsageInfo').text(gasUsage.toFixed(1));
                        jQuery('.estimated-consumption #estimate-gas').text(`${gasUsage} kWh per year`);
                        var elecUsage = quoteBasis.electric.calculationData.consumption;
                        jQuery('#elecUsagereg, #elec-consumption-value').val(elecUsage);
                        jQuery('#elecUsageInfo').text(elecUsage.toFixed(1));
                        jQuery('.estimated-consumption #estimate-elec').text(`${elecUsage} kWh per year`);
                        jQuery('.estimated-usage-wrapper .elecUsage, .estimated-usage-wrapper .gasUsage, #tariff-details-panel .elec-details, #tariff-details-panel .gas-details').addClass('active');
                    } else if (supplyType === "Electric") {
                        var elecUsage = quoteBasis.electric.calculationData.consumption;
                        jQuery('#elecUsagereg, #elec-consumption-value').val(elecUsage);
                        jQuery('#elecUsageInfo').text(elecUsage.toFixed(1));
                        jQuery('.estimated-consumption #estimate-elec').text(`${elecUsage} kWh per year`);
                        jQuery('.estimated-usage-wrapper .elecUsage, #tariff-details-panel .elec-details').addClass('active');
                    } else {
                        jQuery('.your-consumption > div:eq(1)').hide();
                    }
                    jQuery('#profileClassreg').val(quoteBasis.profileClasses[0]);
                    function setTariffData(tariff, prefix) {
                        var elecCalc = tariff.calculated[0],
                            gasCalc = tariff.calculated[1],
                            elecRate = elecCalc.ratesIncVat.unitRateInPence || elecCalc.ratesIncVat.dayUnitRateInPence * 0.58 + elecCalc.ratesIncVat.nightUnitRateInPence * 0.42,
                            elecStanding = parseFloat(elecCalc.ratesIncVat.standingChargeInPence.toFixed(2)),
                            gasRate = supplyType === "Gas and electric" ? parseFloat(gasCalc.ratesIncVat.unitRateInPence.toFixed(2)) : null,
                            gasStanding = supplyType === "Gas and electric" ? parseFloat(gasCalc.ratesIncVat.standingChargeInPence.toFixed(2)) : null;
                        jQuery(`#${prefix}amountElecreg`).val(elecCalc.monthlyAmountInPounds);
                        jQuery(`#${prefix}amountGasreg`).val(gasCalc ? gasCalc.monthlyAmountInPounds : '');
                        jQuery(`#${prefix}TariffIDreg`).val(tariff.tariffId);
                        if (houseChange) {
                            jQuery(`.${prefix}-tariff .cost-box .price.month`).text(`£${tariff.totalMonthly.toLocaleString()}`);
                            jQuery(`.${prefix}-tariff .cost-box .price.year`).text(`£${parseInt(tariff.totalMonthly * 12).toLocaleString()}`);
                        }
                        jQuery(`.annual-price p`).text(`Based on your estimated consumption at an annual rate of £${tariff.totalAnnually.toFixed(2)} (Incl. VAT)`);
                        jQuery(`.${prefix}-tariff #tariff-details-panel #exit-fee`).text(`£${tariff.exitFee} per fuel`);
                        jQuery(`.${prefix}-tariff #tariff-details-panel #elec-price`).text(`${elecRate.toFixed(2)}p per kWh`);
                        jQuery(`.${prefix}-tariff #tariff-details-panel #elec-standing`).text(`${elecStanding}p per day`);
                        if (supplyType === "Gas and electric") {
                            jQuery(`.${prefix}-tariff #tariff-details-panel #gas-price`).text(`${gasRate}p per kWh`);
                            jQuery(`.${prefix}-tariff #tariff-details-panel #gas-standing`).text(`${gasStanding}p per day`);
                        }
                    }
                    setTariffData(fixedTariff, 'fixed');
                    setTariffData(variableTariff, 'variable');
                    function getDaySuffix(day) {
                        if (day >= 11 && day <= 13) {
                            return 'th';
                        }
                        switch (day % 10) {
                            case 1:
                                return 'st';
                            case 2:
                                return 'nd';
                            case 3:
                                return 'rd';
                            default:
                                return 'th';
                        }
                    }
                    function formatDate(date) {
                        let d = new Date(date);
                        let day = d.getDate().toString().replace(/^0+/, '');
                        let month = d.toLocaleString('default', { month: 'long' });
                        let year = d.getFullYear();
                        return `${day}${getDaySuffix(day)} ${month} ${year}`;
                    }
                    jQuery('.cooloffblock .col:eq(0) h2').text(formatDate(jsonData.datesIfCoolOffWaived.contractStartDate));
                    jQuery('.cooloffblock .col:eq(1) h2').text(formatDate(jsonData.datesIfCoolOffNotWaived.contractStartDate));
                    function handleCoolOffOption(answer) {
                        jQuery(".cooling-period-wrapper li.active").removeClass("active");
                        jQuery(`.cooling-period-wrapper li[data-answer='${answer}']`).addClass("active");
                        let waivedDate = formatDate(jsonData.datesIfCoolOffWaived.paymentStartDate),
                            notWaivedDate = formatDate(jsonData.datesIfCoolOffNotWaived.paymentStartDate),
                            startText = answer === 'yes' ? waivedDate : notWaivedDate,
                            cooloffMsg = answer === 'yes' ? 'No cooling off period, your supply starts sooner.' : 'You can cancel within 14 days';
                        jQuery(".cooling-period-wrapper .service-start").html(`Your service will start on <strong>${startText}</strong>`).show();
                        jQuery(".direct-debit #first-payment").text(startText);
                        jQuery('.details-overview #cooloff-info').text(answer === 'yes' ? 'You have waived your 14 day cooling off period.' : 'You can cancel anytime within the first 14 days.');
                        jQuery('#summary-cooloff').text(cooloffMsg);
                        jQuery('#rebelskipcooloff').val(answer === 'yes' ? 'Y' : 'N');
                    }
                    jQuery(document).on("click", ".cooling-period-wrapper li[data-answer='yes']", () => handleCoolOffOption('yes'));
                    jQuery(document).on("click", ".cooling-period-wrapper li[data-answer='no']", () => handleCoolOffOption('no'));
                    jQuery('#summary-address').text(quoteBasis.address.fullAddress);
                    jQuery(".cooling-period-wrapper li[data-answer='yes']").click();
                    // Tariff Type pre-set
                    if (!fixedTariff.calculated[0].isEstimateConsumption) {
                        jQuery(".content-part.consumption").addClass("hidden");
                    }
                    console.log(`tariff type: ${tariffType}`); 
                    if(tariffType == "variable"){
                        jQuery('#productCodereg').val(302);
                        jQuery('#tariffTypereg').val('variable');
                        //
                        jQuery(".content-part.selected-tariff .deal-box.active").removeClass("active");
                        jQuery(`.content-part.selected-tariff .deal-box.variable-tariff`).addClass("active");
                        if (fixedTariff.calculated[0].isEstimateConsumption) {
                            selectConsumption();
                        } else {
                            showForm();
                        }
                    } else if(tariffType == "fixed"){
                        jQuery('#productCodereg').val(297);
                        jQuery('#tariffTypereg').val('fixed');
                        //
                        jQuery(".content-part.selected-tariff .deal-box.active").removeClass("active");
                        jQuery(`.content-part.selected-tariff .deal-box.fixed-tariff`).addClass("active");
                        if (fixedTariff.calculated[0].isEstimateConsumption) {
                            selectConsumption();
                        } else {
                            showForm();
                        }
                    }
                }                
            });
        }
        // Split the query string into an array of key-value pairs
        var queryString = window.location.search.substring(1);
        var queryParams = queryString.split("&");
        // Create an object to store the parameter-value pairs
        var params = {};
        queryParams.forEach(function(param) {
          var pair = param.split("=");
          if (pair.length === 2) {
            var key = decodeURIComponent(pair[0]);
            var value = decodeURIComponent(pair[1]);
            params[key] = value;
          }
        });
        const formname = 'quote-form';
        var fullAddress = params["fullAddress"];
        var street = params["street"];
        var posttown = params["posttown"];
        var postcode = params["postcode"];
        var number = params["number"];
        var uprn = params["uprn"];
        var mpan = params["mpan"];
        var flat = params["flat"];
        var buildingname = params["buildingname"];
        var source = params["source"];
        var ssid = params["ssid"];
        var sid = params["sid"];
        var affiliateID = params["affiliateID"];
        var clickRef = params["click_ref"];
        var journeyStyle = params["style"]; 
        var tariffType = params["tarifftype"]; 
        var optinurl = params["optinurl"]; 
        jQuery('#style').val(journeyStyle)
        jQuery('#mpanreg').val(mpan)
        jQuery('#uprnreg').val(uprn);
        jQuery('#fulladdressreg').val(fullAddress);
        jQuery('#numberreg').val(number);
        jQuery('#streetreg').val(street);
        jQuery('#posttownreg').val(posttown);
        jQuery('#postcodereg').val(postcode);
        jQuery('#source').val(source);
        jQuery('#ssid').val(ssid);
        jQuery('#sid').val(sid);
        jQuery('#rebel-affiliateID').val(affiliateID);
        jQuery('#rebel-click-ref').val(clickRef);
        jQuery("#optinurl").val(optinurl);
        if(params['fuelType'] == "electricityOnly" || params['fueltype'] == "electricityOnly"){
            supplyType = "electric";
            jQuery(".gas-consumption-wrapper").hide();
        } else {
            supplyType = "both";
        }
        var houseType = supplyType; // same as supplyType before but needs changing?
        //
        var numberBedrooms = jQuery(".house-size .choose-bedrooms").attr("data-bedrooms");
        var affiliateID = jQuery('#rebel-affiliateID').val();
        if (houseType == 'house' || houseType == 'flat') {
            var useHouseConsumption = true;
        }
        // Cooling Off Period Popup
        jQuery(document).on("click", "#cooloffPopup", function(){
            jQuery(this).parent().next(".popup-wrapper").addClass("active");
        }); 
        // House Type / Bedrooms
        jQuery(document).on("click", "#add-bedroom", function() {
            var nobedrooms = parseInt(jQuery('#nobedrooms').find('span').text());
            if (nobedrooms <= 14) {
                jQuery('#nobedrooms').find('span').text(nobedrooms + 1);
                var newbed = nobedrooms + 1;
                jQuery('#bed' + newbed).show();
                jQuery(".choose-bedrooms").attr("data-bedrooms", newbed);
            }
        });
        jQuery(document).on("click", "#remove-bedroom", function() {
            var nobedrooms = parseInt(jQuery("#nobedrooms").find("span").text());
            if (nobedrooms >= 2) {
                jQuery("#nobedrooms").find("span").text(nobedrooms - 1);
                var newbed = nobedrooms - 1;
                jQuery("#bed" + newbed).hide();
                jQuery(".choose-bedrooms").attr("data-bedrooms", newbed);
            }
        });
        jQuery(document).on("click", ".rebel-gen-quote-beds", function(){
            houseType = jQuery(".choose-house-type .choose-type.active").attr("data-value");
            numberBedrooms = jQuery(".house-size .choose-bedrooms").attr("data-bedrooms");
            selectTarrif();
            generateRebelQuote(houseType, numberBedrooms, "house-change");
        });
        jQuery(document).on("click", ".choose-house-type .choose-type", function(){
            jQuery(".choose-house-type .choose-type.active").removeClass("active");
            jQuery(this).addClass("active");
        });
        // Variable Select
        jQuery(document).on("click", ".deal-box #variable-tariff", function() {
            // Change productCode-reg
            jQuery('#productCodereg').val(302);
            jQuery('#tariffTypereg').val('variable');
        });
        // Fixed Select
        jQuery(document).on("click", ".deal-box #fixed-tariff", function() {
            jQuery('#productCodereg').val(297);
            jQuery('#tariffTypereg').val('fixed');
        });
        // Go Back
        jQuery(document).on("click", ".content-part #go-back", function() { 
            var $contentPart = jQuery(this).closest(".content-part");
            $contentPart.removeClass("active");
            if($contentPart.prev().hasClass("tariff")){
                selectTarrif();
            } else if($contentPart.prev().hasClass("hidden")){
                selectTarrif();
            } else {
                selectConsumption();
            }
        });
        // View Tariff Details
        jQuery(document).on("click", ".deal-box-inner-bottom .info", function() {
            if(jQuery(this).closest(".deal-box-inner-bottom").find("#tariff-details-panel").hasClass("active")){
                jQuery(this).text("View tariff details");
            } else {
                jQuery(this).text("Hide tariff details");
            }
            jQuery(this).closest(".deal-box-inner-bottom").find("#tariff-details-panel").toggleClass("active");
        });
        // Toggle Tariff Switch
        jQuery(document).on("change", "#togle-swith", function() {
            if (jQuery(this).is(":checked")) {
                jQuery(".content-part.tariff .deal-box.variable-tariff").addClass("active");
                jQuery(".content-part.tariff .deal-box.fixed-tariff").hide();
            } else {
                jQuery(".content-part.tariff .deal-box.variable-tariff").removeClass("active");
                jQuery(".content-part.tariff .deal-box.fixed-tariff").show();
            }
        });        
        jQuery(document).on("click", ".deal-box #fixed-tariff, .deal-box #variable-tariff", function() {
            jQuery(".content-part.selected-tariff").addClass("active");
            jQuery(".content-part:not(.selected-tariff).active").removeClass("active");
            if(jQuery(".content-part.consumption").hasClass("hidden")){ // Consumption hidden, form show
                showForm();
            } else { // Select consumption
                selectConsumption();
            }
            var dealType = jQuery(this).attr("id");
            jQuery(".content-part.selected-tariff .deal-box.active").removeClass("active");
            jQuery(`.content-part.selected-tariff .deal-box.${dealType}`).addClass("active");
        }); 
        // Submit consumption, go next step
        jQuery(document).on("click", ".content-part.consumption #submit-consumption", function() {
            showForm();
        });
        // Update Consumption
        function updateConsumptionQuote(){
            var fuelType = jQuery('#fuelTypereg').val();
            var elecConsumption = parseFloat(jQuery('#elec-consumption-value').val());
            var gasConsumption = parseFloat(jQuery('#gas-consumption-value').val());
            // Fixed Electric Calculations
            var fixElecPrice = parseFloat(jQuery(".fixed-tariff #tariff-details-panel #elec-price").text().replace(/[^\d.]+/g, '')),
                fixElecCost = (elecConsumption * fixElecPrice) / 100;
            var fixElecStand = parseFloat(jQuery(".fixed-tariff #tariff-details-panel #elec-standing").text().replace(/[^\d.]+/g, '')),
                fixElecStandCost = ((fixElecStand * 365) / 100).toFixed(2);
            var fixTotalElec = (parseFloat(fixElecCost) + parseFloat(fixElecStandCost)).toFixed(2);
            // Fixed Gas Calculations
            var fixGasPrice = parseFloat(jQuery(".fixed-tariff #tariff-details-panel #gas-price").text().replace(/[^\d.]+/g, '')),
                fixGasCost = (gasConsumption * fixGasPrice) / 100;
            var fixGasStand = parseFloat(jQuery(".fixed-tariff #tariff-details-panel #gas-standing").text().replace(/[^\d.]+/g, '')),
                fixGasStandCost = ((fixGasStand * 365) / 100).toFixed(2);
            var fixTotalGas = (parseFloat(fixGasCost) + parseFloat(fixGasStandCost)).toFixed(2);
            // Fixed Total Calculation
            var fixTotal, fixMonthly;
            if (fuelType === 'dualFuel') {
                fixTotal = (parseFloat(fixTotalElec) + parseFloat(fixTotalGas)).toFixed(2);
            } else {
                fixTotal = fixTotalElec;
            }
            fixMonthly = (fixTotal / 12).toFixed(2);
            // Update HTML Elements with Fixed Tariff Calculated Values
            jQuery('.fixed-tariff .cost-box .price.month').text("£" + fixMonthly);
            jQuery('.fixed-tariff .cost-box .price.year').text("£" + parseInt(fixMonthly*12).toLocaleString());
            jQuery('.annual-price p').text("Based on your estimated consumption at an annual rate of £" + fixTotal + " (Incl. VAT)");
            // jQuery('#elecUsagereg').val(jQuery('#elec-consumption-value').val());
            // jQuery('#gasUsagereg').val(jQuery('#gas-consumption-value').val());
            jQuery('#fixedamountElecreg').val((fixTotalElec / 12).toFixed(2));
            jQuery('#fixedamountGasreg').val((fixTotalGas / 12).toFixed(2));
            // Variable Electric Calculations
            var varElecPrice = parseFloat(jQuery(".variable-tariff #tariff-details-panel #elec-price").text().replace(/[^\d.]+/g, '')),
                varElecCost = (elecConsumption * varElecPrice) / 100;
            var varElecStand = parseFloat(jQuery(".variable-tariff #tariff-details-panel #elec-standing").text().replace(/[^\d.]+/g, '')),
                varElecStandCost = ((varElecStand * 365) / 100).toFixed(2);
            var varTotalElec = (parseFloat(varElecCost) + parseFloat(varElecStandCost)).toFixed(2);
            // Variable Gas Calculations
            var varGasPrice = parseFloat(jQuery(".variable-tariff #tariff-details-panel #gas-price").text().replace(/[^\d.]+/g, '')),
                varGasCost = (gasConsumption * varGasPrice) / 100;
            var varGasStand = parseFloat(jQuery(".variable-tariff #tariff-details-panel #gas-standing").text().replace(/[^\d.]+/g, '')),
                varGasStandCost = ((varGasStand * 365) / 100).toFixed(2);
            var varTotalGas = (parseFloat(varGasCost) + parseFloat(varGasStandCost)).toFixed(2);
            // Variable Total Calculation
            var varTotal, varMonthly;
            if (fuelType === 'dualFuel') {
                varTotal = (parseFloat(varTotalElec) + parseFloat(varTotalGas)).toFixed(2);
            } else {
                varTotal = varTotalElec;
            }
            varMonthly = (varTotal / 12).toFixed(2);
            // Update HTML Elements with Variable Tariff Calculated Values
            // jQuery('.variable-tariff .cost-box .price.month').text("£" + varMonthly);
            // jQuery('.variable-tariff .cost-box .price.year').text("£" + parseInt(varMonthly*12).toLocaleString());
            jQuery('.annual-price p').text("Based on your estimated consumption at an annual rate of £" + varTotal + " (Incl. VAT)");
            jQuery('#variableamountElecreg').val((varTotalElec / 12).toFixed(2));
            jQuery('#variableamountGasreg').val((varTotalGas / 12).toFixed(2));
        }    
        // Select Consumption
        jQuery(document).on("click", ".consumption-types li", function() {
            var level = jQuery(this).data("level");
            jQuery(".consumption-types li.active").removeClass("active");
            jQuery(this).addClass("active");
            jQuery(".consumption-part.active").removeClass("active");
            jQuery(`.consumption-part.${level}`).addClass("active");
            // Levels
            if (level == "low") {
                var typUsageElec = 1800,
                    typUsageGas = 7500;
                jQuery("#elecUsagereg, #elec-consumption-value").val(typUsageElec);
                jQuery("#gasUsagereg,  #gas-consumption-value").val(typUsageGas);
                updateConsumptionQuote();
            } else if (level == "medium") {
                var typUsageElec = 2700,
                    typUsageGas = 11500;
                jQuery("#elecUsagereg, #elec-consumption-value").val(typUsageElec);
                jQuery("#gasUsagereg,  #gas-consumption-value").val(typUsageGas);
                updateConsumptionQuote();
            } else if (level == "high") {
                var typUsageElec = 4100,
                    typUsageGas = 17000;
                jQuery("#elecUsagereg, #elec-consumption-value").val(typUsageElec);
                jQuery("#gasUsagereg,  #gas-consumption-value").val(typUsageGas);
                updateConsumptionQuote();
            }
            if (level == "own") {
                var typUsageElec = parseInt(jQuery('#elecUsageInfo').text()),
                    typUsageGas = parseInt(jQuery('#gasUsageInfo').text());
                jQuery("#elecUsagereg, #elec-consumption-value").val(typUsageElec);
                jQuery("#gasUsagereg,  #gas-consumption-value").val(typUsageGas);
                jQuery(".info-part.active").removeClass("active");
                jQuery(".info-part.own").addClass("active");
                updateConsumptionQuote(); 
            } else {
                jQuery(".info-part.active").removeClass("active");
                jQuery(".info-part:not(.own)").addClass("active");
            }
        });

        jQuery(document).on("input", "#elec-consumption-value, #gas-consumption-value", function() {
            updateConsumptionQuote();
            jQuery("#elecUsagereg").val(jQuery('#elec-consumption-value').val());
            jQuery("#gasUsagereg").val(jQuery('#gas-consumption-value').val());
        });

        // Join Rebel
        jQuery(document).on("click", "#join-rebel:not(.active)", function () {
            jQuery("#join-rebel").addClass("active");
            // Initialize form validation flag
            let isFormValid = true;
            // Function to display error message and scroll to the top of the form
            function showError(message, scrollTarget) {
                fireNotyf(message, "error");
                jQuery("html, body").animate(
                    { scrollTop: jQuery(scrollTarget).offset().top },
                    "slow"
                );
                jQuery("#join-rebel").removeClass("active");
                isFormValid = false;
            }
            // Validate inputs
            jQuery(".personal-form-wrapper.rebel-form input").each(function () {
                if (jQuery(this).val().trim() === "") {
                    showError(
                        "Please fill in all of your <strong>Personal Details</strong> to switch.",
                        ".content-wrapper"
                    );
                    return false; // Break the loop
                }
            });
            // Validate title (select element)
            if (jQuery("#titleSelect").val() == null) {
                showError(
                    "Please select a <strong>Title</strong> to continue.",
                    ".content-part.form"
                );
                return false;
            }
            // Validate Cooling Off Period
            if (!jQuery(".cooling-period-wrapper li").hasClass("active")) {
                showError(
                    "Please select a <strong>Cooling off Period</strong> option to continue.",
                    ".content-part.form"
                );
                return false;
            }
            // Validate Account Holder
            if (jQuery("input[name='accountholder']").val().trim() === "") {
                showError(
                    "Please enter the <strong>Account Holder Name</strong> as it appears on the account.",
                    ".payment-form-wrapper"
                );
                return false;
            }
            // Validate Account Number
            if (!/^\d{6,8}$/.test(jQuery("input[name='accountnumber']").val().trim())) {
                showError(
                    "<strong>Account Number</strong> must be 6 to 8 digits long. eg, 76811027",
                    ".payment-form-wrapper"
                );
                return false;
            }
            // Validate Sort Code
            let sortCode = jQuery("input[name='sortcode']").val().trim().replace(/-/g, "");
            if (!/^\d{6}$/.test(sortCode)) {
                showError(
                    "<strong>Sort Code</strong> must be 6 digits long. eg, 601611",
                    ".payment-form-wrapper"
                );
                return false;
            }
            // If any validation failed, stop further execution
            if (!isFormValid) return false;
            // Fill hidden fields
            jQuery("#titlereg").val(jQuery("#titleSelect").val());
            jQuery("#firstnamereg").val(jQuery("input[name='firstname']").val());
            jQuery("#lastnamereg").val(jQuery("input[name='lastname']").val());
            jQuery("#emailreg").val(jQuery("input[name='email']").val());
            jQuery("#phonereg").val(jQuery("input[name='phone']").val());
            // Generate Random String
            function generateRandomString(length) {
                const characters =
                    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
                let randomString = "";
                for (let i = 0; i < length; i++) {
                    const randomIndex = Math.floor(Math.random() * characters.length);
                    randomString += characters.charAt(randomIndex);
                }
                return randomString;
            }
            // Collect form data
            const formData = {
                fullAddress: jQuery('input[name="fullAddress-reg"]').val(),
                number: jQuery('input[name="number-reg"]').val(),
                street: jQuery('input[name="street-reg"]').val(),
                flat: jQuery('input[name="flat-reg"]').val(),
                posttown: jQuery('input[name="posttown-reg"]').val(),
                postcode: jQuery('input[name="postcode-reg"]').val().trim(),
                accountHolder: jQuery("input[name='accountholder']").val().trim(),
                accountnumber: jQuery("input[name='accountnumber']").val().trim(),
                sortcode: sortCode,
                profileClass: jQuery('input[name="profileClass-reg"]').val(),
                externalRef: generateRandomString(16),
                title: jQuery("#titlereg").val(),
                firstname: jQuery("#firstnamereg").val(),
                lastname: jQuery("#lastnamereg").val(),
                email: jQuery("#emailreg").val().trim(),
                phone: jQuery("#phonereg").val().trim(),
                mpan: jQuery("#mpanreg").val(),
                uprn: jQuery("#uprnreg").val(),
                mprn: jQuery("#mprnreg").val(),
                elecUsage: parseInt(jQuery("#elecUsagereg").val()),
                gasUsage: parseInt(jQuery("#gasUsagereg").val()),
                fuelType: jQuery("#fuelTypereg").val(),
                tariffType: jQuery("#tariffTypereg").val(),
                skipcooloff: jQuery("#rebelskipcooloff").val(),
                gclid: jQuery("#gclid").val(),
                msclkid: jQuery("#msclkid").val(),
                clickRef: jQuery("#click_ref").val(),
                affiliateID: jQuery("#rebel-affiliateID").val(),
                optinURL: jQuery("#optinurl").val(),
            };
            // Set conditional fields based on product code
            const isFixedProduct = jQuery("#productCode").val() == "297";
            formData.elecPaymentAmount = jQuery(
                isFixedProduct ? "#fixedamountElecreg" : "#variableamountElecreg"
            ).val();
            formData.gasPaymentAmount = jQuery(
                isFixedProduct ? "#fixedamountGasreg" : "#variableamountGasreg"
            ).val();
            formData.productCode = jQuery(
                isFixedProduct ? "#fixedTariffIDreg" : "#variableTariffIDreg"
            ).val();
            // Assign externalRef to the input and handle rebel cookie
            jQuery("#externalref").val(formData.externalRef);
            const rebelAffCookie =
                document.cookie.match("(^|;)\\s*" + "rebel" + "\\s*=\\s*([^;]+)")?.pop() ||
                "";
            if (rebelAffCookie) {
                const [affiliateID, clickRef] = rebelAffCookie.split("-");
                formData.affiliateID = affiliateID;
                formData.clickRef = clickRef;
            }
            // AJAX
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: {
                    action: "post_rebel_customer",
                    fullAddress: formData.fullAddress,
                    number: formData.number,
                    street: formData.street,
                    flat: formData.flat,
                    posttown: formData.posttown,
                    postcode: formData.postcode,
                    accountHolder: formData.accountHolder,
                    accountnumber: formData.accountnumber,
                    sortcode: formData.sortcode,
                    profileClass: formData.profileClass,
                    externalRef: formData.externalRef,
                    title: formData.title,
                    firstname: formData.firstname,
                    lastname: formData.lastname,
                    email: formData.email,
                    phone: formData.phone,
                    mpan: formData.mpan,
                    uprn: formData.uprn,
                    mprn: formData.mprn,
                    elecUsage: formData.elecUsage,
                    gasUsage: formData.gasUsage,
                    fuelType: formData.fuelType,
                    tariffType: formData.tariffType,
                    elecPaymentAmount: formData.elecPaymentAmount,
                    gasPaymentAmount: formData.gasPaymentAmount,
                    productCode: formData.productCode,
                    skipcooloff: formData.skipcooloff,
                    clickRef: formData.clickRef,
                    affiliateID: formData.affiliateID,
                    optinurl: formData.optinURL,
                },
                success: function (data, textStatus, xhr) {
                    if (xhr.status === 200) {
                        if (data.includes("submitted gas usage")) {
                            jQuery("#join-rebel").removeClass("active");
                            jQuery(".payment-form-wrapper .input-error.usage").show();
                        } else if (data.includes("BANK_ACCOUNT_NAME")) {
                            jQuery("#join-rebel").removeClass("active");
                            jQuery(".payment-form-wrapper .input-error.account-name").show();
                        } else if (data.includes("BANK_SORT_CODE")) { 
                            jQuery("#join-rebel").removeClass("active");
                            jQuery(".payment-form-wrapper .input-error.sort-code").show();
                        } else if (data.includes("BANK_ACCOUNT_NUMBER") || data.includes("account_number")) { 
                            jQuery("#join-rebel").removeClass("active");
                            jQuery(".payment-form-wrapper .input-error.account-num").show();
                        } else {
                            if (submit_offer_form_rebel(formname, "Y", data)) return;
                        }
                    } else {
                        console.log(`Error: ${data}`); 
                    }
                },
            });
        });        
        // Submit Rebel Form
        function submit_offer_form_rebel(form_name, rebel_sale, rebel_response) {
            if (rebel_sale !== "N") {
                rebel_sale = "Y";
            }
            let data = {
                campid: jQuery("#" + form_name + " #campid").val() || 0,
                sid: jQuery("#" + form_name + " #sid").val() || 0,
                postcode: jQuery("#" + form_name + " #postcodereg").val() || 0,
                ssid: jQuery("#" + form_name + " #ssid").val() || 0,
                c1: jQuery("#" + form_name + " #c1").val() || 0,
                c2: jQuery("#" + form_name + " #c2").val() || 0,
                c3: jQuery("#" + form_name + " #c3").val() || 0,
                c4: jQuery("#" + form_name + " #c4").val() || 0,
                c5: jQuery("#" + form_name + " #c5").val() || 0,
                c6: jQuery("#" + form_name + " #c6").val() || 0,
                c7: jQuery("#" + form_name + " #c7").val() || 0,
                c8: jQuery("#" + form_name + " #c8").val() || 0,
                c9: jQuery("#" + form_name + " #c9").val() || 0,
                c10: jQuery("#" + form_name + " #c10").val() || 0,
                c11: jQuery("#" + form_name + " #c11").val() || 0,
                campid2: jQuery("#" + form_name + " #campid2").val() || 0,
                source: jQuery("#" + form_name + " #source").val() || 0,
                title: jQuery("#" + form_name + " #titlereg").val() || 0,
                firstname: jQuery("#" + form_name + " #firstnamereg").val() || 0,
                lastname: jQuery("#" + form_name + " #lastnamereg").val() || 0,
                email: jQuery("#" + form_name + " #emailreg").val() || 0,
                gclid: jQuery("#" + form_name + " #gclid").val() || 0,
                msclkid: jQuery("#" + form_name + " #msclkid").val() || 0,
                phone1: jQuery("#" + form_name + " #phonereg").val() || 0,
                number: jQuery("#" + form_name + " #numberreg").val() || 0,
                street1: jQuery("#" + form_name + " #streetreg").val() || 0,
                industry: jQuery("#" + form_name + " #industry").val() || 0,
                dob: jQuery("#" + form_name + " #dobyearreg").val() || 0,
                businessname: jQuery("#" + form_name + " #businessname").val() || 0,
                optinurl: jQuery("#" + form_name + " #optinurl").val() || 0,
                rebel_sale: rebel_sale,
                rebel_fuels: jQuery("#" + form_name + " #fuelTypereg").val(),
                externalref: jQuery("#" + form_name + " #externalref").val(),
                mpan: jQuery("#" + form_name + " #mpanreg").val(),
                uprn: jQuery("#" + form_name + " #uprnreg").val(),
                mprn: jQuery("#" + form_name + " #mprnreg").val(),
                elecUsage: jQuery("#" + form_name + " #elecUsagereg").val(),
                gasUsage: jQuery("#" + form_name + " #gasUsagereg").val(),
                affiliateID: jQuery("#" + form_name + " #rebel-affiliateID").val(), 
                clickRef: jQuery("#" + form_name + " #click_ref").val(),
                paymentAmountElec: jQuery("#" + form_name + " #fixedamountElecreg").val(),
                paymentAmountGas: jQuery("#" + form_name + " #fixedamountGasreg").val(),
                awc: jQuery("#" + form_name + " #awc").val(),
                awcr: jQuery("#" + form_name + " #awcr").val(),
                awpid: jQuery("#" + form_name + " #awpid").val(),
                awbid: jQuery("#" + form_name + " #awbid").val(),
                awgid: jQuery("#" + form_name + " #awgid").val(),
                awaid: jQuery("#" + form_name + " #awaid").val(),
                rebel_response: rebel_response,
                productCode: jQuery("#" + form_name + " #productCodereg").val()
            };
            var rebel_fuels = jQuery("#fuelTypereg").val();
            var externalRef = jQuery("#externalref").val();
            var clickRef = jQuery("#click_ref").val();
            var email = jQuery("#emailreg").val();
            var gclid = jQuery("#gclid").val();
            var msclkid = jQuery("#msclkid").val();;
            // AJAX
            jQuery.ajax({
                url: "https://api.switcheroo.deals/leadbyte-form-submit-v4.php?campid=" + data.campid + "&sid=" + data.sid + "&postcode=" + data.postcode + "&ssid=" + data.ssid + "&c1=" + data.c1 + "&c2=" + data.c2 + "&c3=" + data.c3 + "&c4=" + data.c4 + "&c5=" + data.c5 + "&c6=" + data.c6 + "&c7=" + data.c7 + "&c8=" + data.c8 + "&c9=" + data.c9 + "&c10=" + data.c10 + "&c11=" + data.c11 + "&campid2=" + data.campid2 + "&source=" + data.source + "&firstname=" + data.firstname + "&lastname=" + data.lastname + "&email=" + data.email + "&gclid=" + data.gclid + "&msclkid=" + data.msclkid + "&phone1=" + data.phone1 + "&street1=" + data.number + "," + data.street1 + "&number=" + data.number + "&industry=" + data.industry + "&dob=" + data.dob + "&company=" + data.businessname + "&optinurl=" + data.optinurl + "&rebel_sale=" + data.rebel_sale + "&rebel_fuels=" + data.rebel_fuels + "&rebelexternalref=" + data.externalref + "&mpan=" + data.mpan + "&uprn=" + data.uprn + "&mprn=" + data.mprn + "&elecUsage=" + data.elecUsage + "&gasUsage=" + data.gasUsage + "&title=" + data.title + "&affiliateID=" + data.affiliateID + "&clickRef=" + data.clickRef + "&paymentAmountElectricity=" + data.paymentAmountElec + "&paymentAmountGas=" + data.paymentAmountGas + "&awc=" + data.awc + "&awcr=" + data.awcr + "&awpid=" + data.awpid + "&awbid=" + data.awbid + "&awgid=" + data.awgid + "&awaid=" + data.awaid + "&rebel_response=" + data.rebel_response + "&productCode=" + data.productCode,
                type: "POST",
                enctype: "multipart/form-data",
                data: data,
                success: function (response) {
                    window.location.replace("https://switcheroo.co.uk/comparison-complete/consumer-energy-rebel-thank-you/?fuelType=" + rebel_fuels + "&externalRef=" + externalRef + "&clickRef=" + clickRef + "&email=" + email + "&firstname=" + data.firstname + "&gclid=" + gclid + "&msclkid=" + msclkid + "&awc=" + data.awc + "&awcr=" + data.awcr + "&awpid=" + data.awpid + "&awbid=" + data.awbid + "&awgid=" + data.awgid + "&awaid=" + data.awaid + "&uprn=" + data.uprn);
                    return true;
                },
                error: function (response) {
                    return false;
                }
            });
        }
        // Generate Rebel Quote Based on Load Values
        generateRebelQuote(houseType, numberBedrooms);

        // Comparison Table API to get product codes & prices

        var supplyType = jQuery(`#${formname} #supplyType`).val(),
            currentProvider = jQuery(`#${formname} #currentProvider`).val();
        if(supplyType == 'Both Gas & Electric'){
            supplyType = 'both';
        } else if(supplyType == 'Electric Only'){
            supplyType = 'electric';
        }
        var postcode = jQuery(`#${formname} #postcodereg`).val(),
            houseNumber = jQuery(`#${formname} #numberreg`).val();
        // Need to construct a rebel URL with correct tariff IDs
        // generateRebelURL();
        console.log(supplyType, currentProvider, postcode, houseNumber);
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: 'POST',
            data: {
                action: 'energy_comparison_table',
                postcode: postcode,
                houseNumber: houseNumber,
                supplyType: supplyType,
                currentProvider: currentProvider,
                filters: {
                    sortby: "default"
                }
            },
            success: function(data) {
                try {
                    // Extract the first valid JSON array
                    const cleanData = data.match(/^\[.*?\]/s)[0];  
                    const parsedData = JSON.parse(cleanData);
            
                    let variableTariffs = [];
                    let fixedTariffs = [];
            
                    parsedData.forEach(function(entry) {
                        if (entry.supplier && entry.supplier.toLowerCase().includes('rebel')) {
                            if (entry.tariff && entry.tariff.toLowerCase().includes('variable')) {
                                // Variable Tariff
                                variableTariffs.push({
                                    tariffId: entry.tariffId,
                                    elecAnnualCost: entry.elecAnnualCost,
                                    gasAnnualCost: entry.gasAnnualCost
                                });
                                variableTariffId = entry.tariffId;
                            } else {
                                // Fixed Tariff
                                fixedTariffs.push({
                                    tariffId: entry.tariffId,
                                    elecAnnualCost: entry.elecAnnualCost,
                                    gasAnnualCost: entry.gasAnnualCost
                                });
                                fixedTariffId = entry.tariffId;
                            }
                        }
                    });
            
                    // Set the values based on the tariff type
                    if (fixedTariffId) {
                        var totalAnnually = fixedTariffs[0].elecAnnualCost + fixedTariffs[0].gasAnnualCost,
                            totalMonthly = parseInt(totalAnnually / 12);
                        jQuery(`.fixed-tariff .cost-box .price.year`).text(`£${totalAnnually.toFixed(0)}`);
                        jQuery(`.fixed-tariff .cost-box .price.month`).text(`£${totalMonthly.toFixed(0)}`);
                        // Form updates
                        jQuery('#fixedamountElecreg').val((fixedTariffs[0].elecAnnualCost / 12).toFixed(2));
                        jQuery('#fixedamountGasreg').val((fixedTariffs[0].gasAnnualCost / 12).toFixed(2));
                    }
                    
                    if (variableTariffId) {
                        var totalAnnually = variableTariffs[0].elecAnnualCost + variableTariffs[0].gasAnnualCost,
                            totalMonthly = parseInt(totalAnnually / 12);
                        jQuery(`.variable-tariff .cost-box .price.year`).text(`£${totalAnnually.toFixed(0)}`);
                        jQuery(`.variable-tariff .cost-box .price.month`).text(`£${totalMonthly.toFixed(0)}`);
                        // Form updates
                        jQuery('#variableamountElecreg').val((variableTariffs[0].elecAnnualCost / 12).toFixed(2));
                        jQuery('#variableamountGasreg').val((variableTariffs[0].gasAnnualCost / 12).toFixed(2));
                    }

                    // Hide loader, show deals
                    jQuery(".rebel-journey .page-loader-wrapper").hide();
                    jQuery(".rebel-journey .page-content-wrapper").fadeIn(250);
                } catch (error) {
                    console.error("Error parsing JSON:", error.message, "Raw data:", data);
                }
            },                                       
            error: function(error){
                console.log("Error: " + JSON.stringify(error));
            }
        });
    }
});